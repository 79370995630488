import './Landing.scss';
import './Adaptive.scss';
import './Theme.scss';
import './slides/Slides.scss';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { useEffect, useState } from 'react';

import { ContactDialog } from './dialogs/ContactDialog';
import { Footer } from './components/app/Footer';
import { Header } from './components/app/Header';
import { IContactFormValue } from './interfaces/IContactFormValue';
import { IntroSlide } from './slides/Intro';
import { ProjectSlide } from './slides/Projects';
import { ServicesSlide } from './slides/Services';

function Landing() {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [contactFormVisible, setContactFormVisible] = useState<boolean>(false);
  const [contactFormValue, setContactFormValue] = useState<IContactFormValue>({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section className='landing'>
        <Header {...{ scrollPosition, setContactFormVisible }} />

        <IntroSlide
          {...{ scrollPosition, setContactFormVisible, setContactFormValue }}
        />
        <ProjectSlide />
        <ServicesSlide
          {...{ scrollPosition, setContactFormVisible, setContactFormValue }}
        />

        <Footer />
      </section>
      <ContactDialog
        {...{
          setContactFormVisible,
          contactFormVisible,
          contactFormValue,
          setContactFormValue,
        }}
      />
    </>
  );
}

export default Landing;
