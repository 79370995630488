import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Element } from 'react-scroll';
import Project from '../components/app/Project';
import { useTranslation } from 'react-i18next';

export function ProjectSlide() {
  const { t } = useTranslation();

  return (
    <Element name='slide-2' className='slide'>
      <div className='slide-content'>
        <h2>{t('landing.section2.heading')}</h2>
        <section className='projects'>
          <Project
            title={t('landing.section2.project1')}
            link='https://ditymaybutnyogo.com/'
            image={'/assets/projects/kidsofthefuture.svg'}
          />
          <Project
            title={'UniVent'}
            link='https://univent.events'
            image={'/assets/projects/univent.png'}
          />
          <Project
            title={t('landing.section2.project3')}
            link='https://skrinkaznan.com/'
            image={'/assets/projects/skrinka.svg'}
          />
          <Project
            title={'KidCanvas'}
            link='https://kidcanvas.skrinkaznan.com/'
            image={'/assets/projects/kidcanvas.svg'}
          />
        </section>
      </div>
    </Element>
  );
}
