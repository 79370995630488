import './Header.scss';

import { Dispatch, SetStateAction } from 'react';

import { AnimationTools } from '../../tools/AnimationTools';
import { Button } from '../global/Button';
import { ClientTools } from '../../tools/ClientTools';
import LanguageChange from '../global/LanguageChange';
import { Link } from 'react-scroll';
import Logo from '../../assets/indago-logo-web.svg';
import { t } from 'i18next';
import { useWindowSize } from '../../tools/WindowSize';

export interface IHeader {
  scrollPosition: number;
  setContactFormVisible: Dispatch<SetStateAction<boolean>>;
}

export function Header(props: IHeader) {
  const windowSize = useWindowSize();

  return (
    <header className='landing-header'>
      <div className='brand-logo'>
        <Link to='slide-1' smooth={true} duration={1500}>
          <img
            alt='IndagoDev logo'
            src={Logo}
            style={{
              opacity: AnimationTools.easeInExpo(
                Math.min(
                  1,
                  props.scrollPosition / (windowSize?.height ?? 0) ?? 0
                )
              ),
            }}
          />
        </Link>
      </div>
      <Link
        className='nav-link'
        offset={-50}
        activeClass='active'
        to='slide-1'
        spy={true}
        smooth={true}
        duration={1500}
      >
        {t('nav.home')}
      </Link>
      <Link
        className='nav-link'
        offset={-50}
        activeClass='active'
        to='slide-2'
        spy={true}
        smooth={true}
        duration={1500}
      >
        {t('nav.projects')}
      </Link>
      <Link
        className='nav-link'
        offset={-50}
        activeClass='active'
        to='slide-3'
        spy={true}
        smooth={true}
        duration={1500}
      >
        {t('nav.services')}
      </Link>
      <div className='right-side'>
        <LanguageChange
          onChange={(lang: string) => ClientTools.setQueryParam('lang', lang)}
          language={ClientTools.getPrefferedLanguage()}
        />
        <Button
          className='action-button'
          onClick={() => props.setContactFormVisible(true)}
        >
          {t('cta.email')}
        </Button>
      </div>
    </header>
  );
}
