import { t } from "i18next";
import { AsyncForm } from "../components/global/AsyncForm";
import { Dialog } from "../components/global/Dialog";
import { LoadingOverlay } from "../components/global/LoadingOverlay";
import { TextArea } from "../components/global/TextArea";
import { TextInput } from "../components/global/TextInput";
import { ValidationTools } from "../tools/ValidationTools";
import { Dispatch, SetStateAction, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { IContactFormValue } from "../interfaces/IContactFormValue";
import { Button } from "../components/global/Button";

export interface IContactDialog {
  setContactFormVisible: Dispatch<SetStateAction<boolean>>;
  contactFormVisible: boolean;
  contactFormValue: IContactFormValue;
  setContactFormValue: Dispatch<SetStateAction<IContactFormValue>>;
}

export function ContactDialog(props: IContactDialog) {
  const [contactFormLoading, setContactFormLoading] = useState<boolean>(false);
  const [contactFormSubmitted, setContactFormSubmitted] =
    useState<boolean>(false);

  const isContactFormValid =
    ValidationTools.isEmailValid(props.contactFormValue.email) &&
    props.contactFormValue.message.length > 0 &&
    props.contactFormValue.name.length > 0 &&
    props.contactFormValue.subject.length > 0;

  async function handleOnContactFormSubmit() {
    setContactFormSubmitted(true);

    if (isContactFormValid) {
      setContactFormLoading(true);

      try {
        let formData = new FormData();

        formData.append("name", props.contactFormValue.name);
        formData.append("email", props.contactFormValue.email);
        formData.append("subject", props.contactFormValue.subject);
        formData.append("message", props.contactFormValue.message);

        const response = await axios
          .post(`${process.env.REACT_APP_BACKEND_URI}/send.php`, formData)
          .then((response) => {
            return response.data;
          });

        if (response) {
          toast.success(t("contactForm.messageSendedSuccessfuly"));
          props.setContactFormValue({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        } else {
          toast.error(t("contactForm.messageSendedWithError"));
        }
      } catch (error) {
        toast.error(t("contactForm.messageSendedWithError"));
      }

      props.setContactFormVisible(false);
      setContactFormSubmitted(false);

      setContactFormLoading(false);
    } else {
    }
  }

  return (
    <Dialog
      className="contact-dialog"
      onClose={() => {
        props.setContactFormVisible(false);
        setContactFormSubmitted(false);
      }}
      active={props.contactFormVisible}
    >
      <AsyncForm onSubmit={handleOnContactFormSubmit}>
        <TextInput
          value={props.contactFormValue.name}
          required={true}
          isValid={ValidationTools.isRequiredFieldValueValid(
            props.contactFormValue.name,
            contactFormSubmitted
          )}
          validationError={t("validationError.required") ?? ""}
          onChange={(e) =>
            props.setContactFormValue((prevValue) => ({
              ...prevValue,
              name: e.target.value,
            }))
          }
          icon="person"
          type="text"
          placeholder={t("contactForm.namePlaceholder") ?? ""}
          label={t("contactForm.nameLabel") ?? ""}
        />
        <TextInput
          value={props.contactFormValue.email}
          required={true}
          isValid={ValidationTools.isEmailFieldValueValid(
            props.contactFormValue.email,
            contactFormSubmitted
          )}
          validationError={
            props.contactFormValue.email.length === 0
              ? t("validationError.required") ?? ""
              : t("validationError.invalidEmail") ?? ""
          }
          onChange={(e) =>
            props.setContactFormValue((prevValue) => ({
              ...prevValue,
              email: e.target.value,
            }))
          }
          type="email"
          icon="envelope"
          placeholder={t("contactForm.emailPlaceholder") ?? ""}
          label={t("contactForm.emailLabel") ?? ""}
        />
        <TextInput
          value={props.contactFormValue.subject}
          required={true}
          isValid={ValidationTools.isRequiredFieldValueValid(
            props.contactFormValue.subject,
            contactFormSubmitted
          )}
          validationError={t("validationError.required") ?? ""}
          onChange={(e) =>
            props.setContactFormValue((prevValue) => ({
              ...prevValue,
              subject: e.target.value,
            }))
          }
          type="text"
          icon="box"
          placeholder={t("contactForm.subjectPlaceholder") ?? ""}
          label={t("contactForm.subjectLabel") ?? ""}
        />
        <TextArea
          value={props.contactFormValue.message}
          required={true}
          isValid={ValidationTools.isRequiredFieldValueValid(
            props.contactFormValue.message,
            contactFormSubmitted
          )}
          validationError={t("validationError.required") ?? ""}
          onChange={(e) =>
            props.setContactFormValue((prevValue) => ({
              ...prevValue,
              message: e.target.value,
            }))
          }
          rows={5}
          maxLength={250}
          icon="file-text"
          placeholder={t("contactForm.messagePlaceholder") ?? ""}
          label={t("contactForm.messageLabel") ?? ""}
        />
        <Button
          iconPosition="right"
          icon="send"
          className="submit"
          primary={isContactFormValid}
          text={t("common.submit") ?? ""}
          type="submit"
        />
      </AsyncForm>

      <LoadingOverlay active={contactFormLoading} />
    </Dialog>
  );
}
