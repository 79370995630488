import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Element } from 'react-scroll';
import { ISlide } from '../interfaces/ISlide';
import Product from '../components/app/Product';
import Product_1 from '../assets/images/products/1.svg';
import Product_2 from '../assets/images/products/2.svg';
import Product_3 from '../assets/images/products/3.svg';
import Product_4 from '../assets/images/products/4.svg';
import { useTranslation } from 'react-i18next';

export function ServicesSlide(props: ISlide) {
  const { t } = useTranslation();

  return (
    <Element name='slide-3' className='slide last'>
      <div className='slide-content'>
        <h2>{t('landing.section3.heading')}</h2>
        <div className='products'>
          <Product
            title={t('landing.section3.service1')}
            image={Product_1}
            onClick={(e) => {
              e.preventDefault();

              props.setContactFormValue((prevValue) => ({
                ...prevValue,
                subject: t('landing.section3.service1'),
              }));
              props.setContactFormVisible(true);
            }}
          />
          <Product
            title={t('landing.section3.service2')}
            image={Product_2}
            onClick={(e) => {
              e.preventDefault();
              props.setContactFormValue((prevValue) => ({
                ...prevValue,
                subject: t('landing.section3.service2'),
              }));
              props.setContactFormVisible(true);
            }}
          />
          <Product
            title={t('landing.section3.service3')}
            image={Product_3}
            onClick={(e) => {
              e.preventDefault();
              props.setContactFormValue((prevValue) => ({
                ...prevValue,
                subject: t('landing.section3.service3'),
              }));
              props.setContactFormVisible(true);
            }}
          />
          {/**<Product
            title={t('landing.section3.service4')}
            image={Product_4}
            onClick={(e) => {
              e.preventDefault();
              props.setContactFormVisible(true);
            }}
          /> */}
        </div>
      </div>
    </Element>
  );
}
