import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Element, Link } from 'react-scroll';

import ArrowDown from '../assets/arrow-down.svg';
import { Button } from '../components/global/Button';
import { ISlide } from '../interfaces/ISlide';
import LandingBG_1366 from '../assets/images/landing-bg-1366.jpg';
import LandingBG_1920 from '../assets/images/landing-bg-1920.jpg';
import LandingBG_2560 from '../assets/images/landing-bg-2560.jpg';
import LandingBG_3564 from '../assets/images/landing-bg-3564.jpg';
import LandingBG_480 from '../assets/images/landing-bg-480.jpg';
import LandingBG_720 from '../assets/images/landing-bg-720.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Logo from '../assets/indago-logo-web.svg';
import { useTranslation } from 'react-i18next';

export function IntroSlide(props: ISlide) {
  const { t } = useTranslation();

  return (
    <Element name='slide-1' className='slide full'>
      <LazyLoadImage
        effect='opacity'
        alt='Landing background'
        className='background'
        style={{ top: props.scrollPosition / 3 }}
        src={LandingBG_480}
        srcSet={`
          ${LandingBG_480} 480w,
          ${LandingBG_720} 720w,
          ${LandingBG_1366} 1366w,
          ${LandingBG_1920} 1920w,
          ${LandingBG_2560} 2560w,
          ${LandingBG_3564} 3564w
        `}
        sizes='
          (max-width: 480px) 480px,
          (max-width: 720px) 720px,
          (max-width: 1366px) 1366px,
          (max-width: 1920px) 1920px,
          (max-width: 2560px) 2560px,
          (max-width: 3564px) 3564px,
        '
      />
      <div className='big-text'>
        <div>
          <img className='logo' alt='IndagoDev logo' src={Logo} />
          <p>{t('landing.section1.text1')}</p>
          <Button
            onClick={() => props.setContactFormVisible(true)}
            icon='headset'
            text={t('cta.email') ?? ''}
          />
          <Link to='slide-2' offset={-50} smooth={true} duration={1500}>
            <div className='call-to-scroll-label'>
              <img alt='Arrow down' src={ArrowDown} />
              <span>{t('landing.section1.cta')}</span>
            </div>
          </Link>
        </div>
      </div>
    </Element>
  );
}
