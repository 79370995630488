import './Footer.scss';

import Social_Icon_Telegram from '../../assets/social/telegram.svg';

export function Footer() {
  return (
    <footer className='landing-footer'>
      <div className='left-side'>
        <div className='copyright'>© IndagoDev {new Date().getFullYear()}</div>
        <div className='links'>
          <a
            target='_blank'
            rel='noreferrer'
            href='/assets/pdf/indago-privacy-policy.pdf'
          >
            Privacy policy
          </a>
          <span style={{ opacity: 0.5 }}>|</span>
          <a
            target='_blank'
            rel='noreferrer'
            href='/assets/pdf/indago-terms-of-use.pdf'
          >
            Terms of use
          </a>
        </div>
      </div>
      <div className='right-side social-links'>
        <a target='_blank' rel='noreferrer' href='https://t.me/indagodev'>
          <img alt='Telegram' src={Social_Icon_Telegram} />
        </a>
      </div>
    </footer>
  );
}
